import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-commitment',
  templateUrl: './our-commitment.component.html',
  styleUrls: ['./our-commitment.component.scss', '../scss/about-general.scss']
})
export class OurCommitmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
