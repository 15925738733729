import {Component, OnInit} from '@angular/core';
import {TabPageComponent} from '../../../components/commons/TabPageComponent';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-how-we-work',
  templateUrl: './how-we-work.component.html',
  styleUrls: ['./how-we-work.component.scss']
})
export class HowWeWorkComponent extends TabPageComponent implements OnInit {

  public dashBoardUrl;

  constructor() {
    super();
    this.dashBoardUrl = environment.dashboardURL + '/register';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
