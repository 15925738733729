import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router, private titleService: Title) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setTitle();
        if (environment.production) {
          //  Google Analytics
          gtag('config', 'UA-176152078-1', {
            page_path: event.urlAfterRedirects
          });
        }
      }
    });
  }

  ngOnInit(): void {

  }

  private setTitle() {
    let route: ActivatedRoute = this.router.routerState.root;
    if (route) {
      while (route.firstChild) {
        route = route.firstChild;
      }
      if (route.snapshot.data['title']) {
        this.titleService.setTitle(`${route.snapshot.data['title']} - InnPorting`);
      } else {
        this.titleService.setTitle('Innovative Porting - InnPorting');
      }
    }
  }
}




