import {TranslateService} from '@ngx-translate/core';
import {LocalStorageHelper} from './LocalStorageHelper';
import {Language} from '../models/Language';

export class LanguageHelper {

  public static readonly LANG_ES = 'es';
  public static readonly LANG_EN = 'en';
  public static readonly LANG_ES_COMPLETE_NAME = 'Español';
  public static readonly LANG_EN_COMPLETE_NAME = 'English';
  public static readonly FLAG_ES = 'flag-es';
  public static readonly FLAG_EN = 'flag-en';


  private _languagesMap: Map<String, Language> = new Map<String, Language>();
  get languagesMap(): Map<String, Language> {
    return this._languagesMap;
  }

  constructor(private translateService: TranslateService) {
  }

  initLanguage() {
    this.fillMapForLanguages();
    this.translateService.addLangs([LanguageHelper.LANG_ES, LanguageHelper.LANG_EN]);
    this.translateService.setDefaultLang(LanguageHelper.LANG_EN);
    this.setLanguage();
  }

  private fillMapForLanguages() {
    this._languagesMap.set(LanguageHelper.LANG_ES, new Language(LanguageHelper.LANG_ES_COMPLETE_NAME, LanguageHelper.FLAG_ES));
    this._languagesMap.set(LanguageHelper.LANG_EN, new Language(LanguageHelper.LANG_EN_COMPLETE_NAME, LanguageHelper.FLAG_EN));
  }

  // TODO: In future, take care of stored DB user language.
  private setLanguage() {
    let lang: string;
    if (LocalStorageHelper.existsItem(LocalStorageHelper.LANG_KEY)) {
      lang = LocalStorageHelper.getItem(LocalStorageHelper.LANG_KEY);
    } else {
      if (this.existLangInLangsArray(this.getBrowserLang())) {
        lang = this.getBrowserLang();
      } else {
        lang = LanguageHelper.LANG_EN;
      }
    }
    this.translateService.use(lang);
  }

  private existLangInLangsArray(lang: string) {
    return this.getLangs().indexOf(lang) !== -1;
  }

  private getLangs() {
    return this.translateService.getLangs();
  }

  public getFilteredMenuLangs() {
    return this.translateService.getLangs().filter(lang => lang !== this.getCurrentLang());
  }

  private getBrowserLang(): string {
    return this.translateService.getBrowserLang();
  }

  changeLanguage(lang: string) {
    this.translateService.use(lang);
    LocalStorageHelper.setItem(LocalStorageHelper.LANG_KEY, lang);
  }

  getCurrentLang() {
    return this.translateService.currentLang;
  }
}
