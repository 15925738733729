export class NavbarPage {

  private pageName: string;
  private _isTransparent: boolean;
  get isTransparent(): boolean {
    return this._isTransparent;
  }

  constructor(pageName: string, isTransparent: boolean) {
    this.pageName = pageName;
    this._isTransparent = isTransparent;
  }
}
