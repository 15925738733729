import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit, AfterViewInit {

  static STYLE_COLOR_TITLE_COLOR = '#2c2c2c';

  @ViewChild('leftContainer')
  private leftContainer;
  @ViewChild('rightContainer')
  private rightContainer;

  public customOptions: OwlOptions = {
    items: 3,
    nav: true,
    margin: 50,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      750: {
        items: 2
      },
      1000: {
        items: 3
      }
    },
    loop: true,
    navText: [
      '<div class="icon ui-kit-normal"><i class="flaticon-arrow"></i></div>',
      '<div class="icon ui-kit-normal"><i class="flaticon-arrow-point-to-right"></i></div>'
    ]
  };

  constructor(private elem: ElementRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.configurePrevElement();
    this.configureNextElement();
    this.configureOwlDots();
  }

  configurePrevElement() {
    const element = this.elem.nativeElement.querySelector('.owl-prev');
    element.style.background = 'none';
    element.style.color = TestimonialsComponent.STYLE_COLOR_TITLE_COLOR;
    element.style.padding = 0;
    element.style.margin = 0;
    this.leftContainer.nativeElement.appendChild(element);
  }

  configureNextElement() {
    const element = this.elem.nativeElement.querySelector('.owl-next');
    element.style.background = 'none';
    element.style.color = TestimonialsComponent.STYLE_COLOR_TITLE_COLOR;
    element.style.padding = 0;
    element.style.margin = 0;
    this.rightContainer.nativeElement.appendChild(element);
  }

  configureOwlDots() {
    const dots = this.elem.nativeElement.querySelector('.owl-dots');
    dots.style.position = 'relative';
    dots.style.top = '-50px';
  }

}
