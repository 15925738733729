import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.scss']
})

export class FaqItemComponent implements OnInit {

  static readonly FAQ_ACTIVE_CLASS = 'faq-active';
  static readonly ARROW_UP_CLASS = 'arrow-up';

  @ViewChild('faqItemContent') faqItemContent: ElementRef;
  @ViewChild('faqItemIcon') faqItemIcon: ElementRef;

  @Input()
  public title: string;

  @Input()
  public content: string;


  ngOnInit() {

  }


  titleClick() {
    const contentElemClassList = this.faqItemContent.nativeElement.classList;
    if (contentElemClassList.contains(FaqItemComponent.FAQ_ACTIVE_CLASS)) {
      this.hideContent();
    } else {
      this.showContent();
    }
  }

  showContent() {
    this.hideAllContents();
    this.setAllArrowsDown();


    const contentElemClassList = this.faqItemContent.nativeElement.classList;
    const iconElemClassList = this.faqItemIcon.nativeElement.classList;
    iconElemClassList.add(FaqItemComponent.ARROW_UP_CLASS);
    contentElemClassList.add(FaqItemComponent.FAQ_ACTIVE_CLASS);
  }

  hideContent() {
    const contentElemClassList = this.faqItemContent.nativeElement.classList;
    const iconElemClassList = this.faqItemIcon.nativeElement.classList;
    iconElemClassList.remove(FaqItemComponent.ARROW_UP_CLASS);
    contentElemClassList.remove(FaqItemComponent.FAQ_ACTIVE_CLASS);
  }

  hideAllContents() {
    const elems: HTMLCollectionOf<Element> = document.getElementsByClassName(FaqItemComponent.FAQ_ACTIVE_CLASS);
    for (const elem in elems) {
      if (elems[elem].classList !== undefined && elems[elem].classList.contains(FaqItemComponent.FAQ_ACTIVE_CLASS)) {
        elems[elem].classList.remove(FaqItemComponent.FAQ_ACTIVE_CLASS);
      }
    }
  }

  // Set all arrows down
  setAllArrowsDown() {
    const elems: HTMLCollectionOf<Element> = document.getElementsByClassName(FaqItemComponent.ARROW_UP_CLASS);
    for (const elem in elems) {
      if (elems[elem].classList !== undefined && elems[elem].classList.contains(FaqItemComponent.ARROW_UP_CLASS)) {
        elems[elem].classList.remove(FaqItemComponent.ARROW_UP_CLASS);
      }
    }
  }
}


