import {Component, OnInit} from '@angular/core';
import {TabPageComponent} from '../../../components/commons/TabPageComponent';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent extends TabPageComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
