import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-road-shipments',
  templateUrl: './road-shipments.component.html',
  styleUrls: ['./road-shipments.component.scss']
})
export class RoadShipmentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
