import {OnInit} from '@angular/core';

export class BasePageComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
  }

}
