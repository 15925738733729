import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mission-vision',
  templateUrl: './mission-vision.component.html',
  styleUrls: ['./mission-vision.component.scss', '../scss/about-general.scss']
})
export class MissionVisionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
