export class Language {

  private _completeName: String;
  get completeName(): String {
    return this._completeName;
  }

  private _flagClass: String;
  get flagClass(): String {
    return this._flagClass;
  }

  constructor(completeName: String, flagClass: String) {
    this._completeName = completeName;
    this._flagClass = flagClass;
  }
}
