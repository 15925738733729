import {LocalStorageHelper} from './LocalStorageHelper';

export class TabHelper {
  readonly TAB_COMPANY: string = 'company';
  readonly TAB_FORWARDER: string = 'forwarder';

  constructor() {

  }

  getCurrentTab() {
    return LocalStorageHelper.existsItem(LocalStorageHelper.TAB_SELECTED) ?
      LocalStorageHelper.getItem(LocalStorageHelper.TAB_SELECTED) : this.TAB_COMPANY;
  }

  setTab(tab: string) {
    LocalStorageHelper.setItem(LocalStorageHelper.TAB_SELECTED, tab);
  }

}
