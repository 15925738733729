import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SeaShipmentsComponent} from './pages/solutions/sea-shipments/sea-shipments.component';
import {LandingComponent} from './pages/landing/landing.component';
import {RoadShipmentsComponent} from './pages/solutions/road-shipments/road-shipments.component';
import {HowWeWorkComponent} from './pages/solutions/how-we-work/how-we-work.component';
import {AirShipmentsComponent} from './pages/solutions/air-shipments/air-shipments.component';
import {MissionVisionComponent} from './pages/about/mission-vision/mission-vision.component';
import {OurCommitmentComponent} from './pages/about/our-commitment/our-commitment.component';
import {TeamComponent} from './pages/about/team/team.component';
import {WorkWithUsComponent} from './pages/about/work-with-us/work-with-us.component';
import {TrackTraceComponent} from './pages/support/track-trace/track-trace.component';
import {BlogComponent} from './pages/support/blog/blog.component';
import {ContactUsComponent} from './pages/support/contact-us/contact-us.component';
import {FaqComponent} from './pages/support/faq/faq.component';
import {LegalNoticeConditionsComponent} from './pages/support/legal-notice-conditions/legal-notice-conditions.component';
import {ServiceAgreementComponent} from './pages/support/service-agreement/service-agreement.component';

const routes: Routes = [
  {path: '', redirectTo: 'landing', pathMatch: 'full'},
  {path: 'landing', component: LandingComponent},
  {path: 'road-shipments', component: RoadShipmentsComponent, data: {title: 'Road shipments'}},
  {path: 'sea-shipments', component: SeaShipmentsComponent, data: {title: 'Sea shipments'}},
  {path: 'air-shipments', component: AirShipmentsComponent, data: {title: 'Air shipments'}},
  {path: 'mission-vision', component: MissionVisionComponent, data: {title: 'Mission and vision'}},
  {path: 'our-commitment', component: OurCommitmentComponent, data: {title: 'Our commitment'}},
  {path: 'team', component: TeamComponent, data: {title: 'Team'}},
  {path: 'work-with-us', component: WorkWithUsComponent, data: {title: 'Work with us'}},
  {path: 'track-trace', component: TrackTraceComponent, data: {title: 'Shipment tracking'}},
  {path: 'blog', component: BlogComponent, data: {title: 'Blog'}},
  {path: 'faq', component: FaqComponent, data: {title: 'Faq'}},
  {path: 'contact-us', component: ContactUsComponent, data: {title: 'Contact us'}},
  {path: 'legal-notice-conditions', component: LegalNoticeConditionsComponent, data: {title: 'Legal Notice and conditions'}},
  {path: 'legal-notice-conditions/:sectionId', component: LegalNoticeConditionsComponent, data: {title: 'Legal Notice and conditions'}},
  {path: '**', redirectTo: 'landing'} // For not found
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
