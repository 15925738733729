import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-legal-notice-conditions',
  templateUrl: './legal-notice-conditions.component.html',
  styleUrls: ['./legal-notice-conditions.component.scss']
})
export class LegalNoticeConditionsComponent implements OnInit {
  private initialContent: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    const sectionId = this.route.snapshot.paramMap.get('sectionId');
    if (sectionId) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        this.initialContent = sectionElement.innerText;
        this.scrollWhenContentTranslated(sectionElement);
      } else {
        this.router.navigateByUrl('/landing');
      }
    }
  }

  reUseLink() {
    this.router.navigateByUrl('/legal-notice-conditions');
  }

  private scrollWhenContentTranslated(sectionElement) {
    if (this.initialContent !== sectionElement.innerText) {
      const sectionYPosition = sectionElement.offsetTop;
      scrollTo({top: sectionYPosition + (window.innerHeight / 2)});
    } else {
      setTimeout(() => {
        this.scrollWhenContentTranslated(sectionElement);
      }, 200);
    }
  }
}
