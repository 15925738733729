import {NavbarPage} from '../models/NavbarPage';

export class NavbarHelper {

  static readonly NAVBAR_CLOSED_WIDTH_ISSUE = 992;
  static readonly NAVBAR_CLOSE_INDICATOR_WIDTH = 991;
  static readonly NAVBAR_MAXIMUM_Y_FOR_TOGGLE_TRANSPARENT = 150;

  static readonly SOLUTIONS_NAME = 'solutions';
  static readonly ABOUT_NAME = 'about';
  static readonly SUPPORT_NAME = 'support';
  static readonly LOGIN_NAME = 'login';

  private static readonly LANDING_PAGE = '/landing';

  private static readonly HOW_WE_WORK_PAGE = '/how-we-work';
  private static readonly ROAD_SHIPMENTS_PAGE = '/road-shipments';
  private static readonly SEA_SHIPMENTS_PAGE = '/sea-shipments';
  private static readonly AIR_SHIPMENTS_PAGE = '/air-shipments';

  private static readonly MISSION_VISION_PAGE = '/mission-vision';
  private static readonly OUR_COMMITMENT_PAGE = '/our-commitment';
  private static readonly TEAM_PAGE = '/team';
  private static readonly WORK_WITH_US_PAGE = '/work-with-us';

  private static readonly TRACK_TRACE_PAGE = '/track-trace';
  private static readonly BLOG_PAGE = '/blog';
  private static readonly FAQ_PAGE = '/faq';
  private static readonly CONTACT_US_PAGE = '/contact-us';
  private static readonly LEGAL_NOTICE_CONDITIONS = '/legal-notice-conditions';
  private static readonly SERVICE_AGREEMENT = '/service-agreement';

  private static readonly LOGIN_PAGE = '/login';
  private static readonly REGISTER_PAGE = '/register';

  static readonly ARRAY_SOLUTIONS: Array<string> = [NavbarHelper.HOW_WE_WORK_PAGE, NavbarHelper.ROAD_SHIPMENTS_PAGE,
    NavbarHelper.SEA_SHIPMENTS_PAGE, NavbarHelper.AIR_SHIPMENTS_PAGE];
  static readonly ARRAY_ABOUT: Array<string> = [NavbarHelper.MISSION_VISION_PAGE, NavbarHelper.OUR_COMMITMENT_PAGE,
    NavbarHelper.TEAM_PAGE, NavbarHelper.WORK_WITH_US_PAGE];
  static readonly ARRAY_SUPPORT: Array<string> = [NavbarHelper.TRACK_TRACE_PAGE, NavbarHelper.BLOG_PAGE,
    NavbarHelper.FAQ_PAGE, NavbarHelper.CONTACT_US_PAGE, NavbarHelper.LEGAL_NOTICE_CONDITIONS, NavbarHelper.SERVICE_AGREEMENT];
  static readonly ARRAY_LOGIN: Array<string> = [NavbarHelper.LOGIN_PAGE, NavbarHelper.REGISTER_PAGE];

  private _pagesMap: Map<string, NavbarPage> = new Map<string, NavbarPage>();
  get pagesMap(): Map<string, NavbarPage> {
    return this._pagesMap;
  }

  constructor() {
    this.fillPagesMap();
  }

  private fillPagesMap() {
    this._pagesMap.set(NavbarHelper.LANDING_PAGE, new NavbarPage(NavbarHelper.LANDING_PAGE,
      this.getTransparentFlag(NavbarHelper.LANDING_PAGE)));
    this.setArrayToMap(NavbarHelper.SOLUTIONS_NAME, NavbarHelper.ARRAY_SOLUTIONS);
    this.setArrayToMap(NavbarHelper.ABOUT_NAME, NavbarHelper.ARRAY_ABOUT);
    this.setArrayToMap(NavbarHelper.SUPPORT_NAME, NavbarHelper.ARRAY_SUPPORT);
    this.setArrayToMap(NavbarHelper.LOGIN_NAME, NavbarHelper.ARRAY_LOGIN);
  }

  private setArrayToMap(pageSetName: string, pageSet: Array<string>) {
    const transparent = this.getTransparentFlag(pageSetName);
    for (const page of pageSet) {
      this._pagesMap.set(page, new NavbarPage(page, transparent));
    }
  }

  private getTransparentFlag(page: string): boolean {
    let transparent = false;
    switch (page) {
      case NavbarHelper.LANDING_PAGE:
        transparent = true;
        break;
      case NavbarHelper.SOLUTIONS_NAME:
        transparent = true;
        break;
      case NavbarHelper.ABOUT_NAME:
        transparent = true;
        break;
      case NavbarHelper.SUPPORT_NAME:
        transparent = true;
        break;
      case NavbarHelper.LOGIN_NAME:
        transparent = true;
        break;
    }
    return transparent;
  }

  public getTransparentFlagFromPagesMap(page: string): boolean {
    let result = false;
    if (this.pagesMap != null && this.pagesMap.has(page)) {
      result = this.pagesMap.get(page).isTransparent;
    }
    return result;
  }
}
