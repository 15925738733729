import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './components/navbar/navbar.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LandingComponent} from './pages/landing/landing.component';
import {SeaShipmentsComponent} from './pages/solutions/sea-shipments/sea-shipments.component';
import {FooterComponent} from './components/footer/footer.component';
import {FormsModule} from '@angular/forms';
import {RoadShipmentsComponent} from './pages/solutions/road-shipments/road-shipments.component';
import {HowWeWorkComponent} from './pages/solutions/how-we-work/how-we-work.component';
import {AirShipmentsComponent} from './pages/solutions/air-shipments/air-shipments.component';
import {MissionVisionComponent} from './pages/about/mission-vision/mission-vision.component';
import {OurCommitmentComponent} from './pages/about/our-commitment/our-commitment.component';
import {TeamComponent} from './pages/about/team/team.component';
import {WorkWithUsComponent} from './pages/about/work-with-us/work-with-us.component';
import {TrackTraceComponent} from './pages/support/track-trace/track-trace.component';
import {BlogComponent} from './pages/support/blog/blog.component';
import {FaqComponent} from './pages/support/faq/faq.component';
import {ContactUsComponent} from './pages/support/contact-us/contact-us.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FaqItemComponent} from './pages/support/faq-item/faq-item.component';
import {LegalNoticeConditionsComponent} from './pages/support/legal-notice-conditions/legal-notice-conditions.component';
import {TestimonialsComponent} from './pages/landing/testimonials/testimonials.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CookieConsentComponent} from './components/commons/cookie-consent/cookie-consent.component';
import { ServiceAgreementComponent } from './pages/support/service-agreement/service-agreement.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LandingComponent,
    SeaShipmentsComponent,
    FooterComponent,
    RoadShipmentsComponent,
    HowWeWorkComponent,
    AirShipmentsComponent,
    MissionVisionComponent,
    OurCommitmentComponent,
    TeamComponent,
    WorkWithUsComponent,
    TrackTraceComponent,
    BlogComponent,
    FaqComponent,
    ContactUsComponent,
    FaqItemComponent,
    LegalNoticeConditionsComponent,
    TestimonialsComponent,
    CookieConsentComponent,
    ServiceAgreementComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
