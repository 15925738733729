import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-work-with-us',
  templateUrl: './work-with-us.component.html',
  styleUrls: ['./work-with-us.component.scss', '../scss/about-general.scss']
})
export class WorkWithUsComponent implements OnInit {

  public focus;
  public focus1;
  public focus2;
  public focus3;
  public focus4;
  public focus5;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  sendMailToHello() {
    const name = (document.getElementById('name') as HTMLInputElement).value;
    const mail = (document.getElementById('mail') as HTMLInputElement).value;
    const phone = (document.getElementById('phone') as HTMLInputElement).value;

    const translatedSubject = this.translateService.instant('MAILS.SUBJECT_WORK_WITH_US_SUBJECT');
    const translatedPresentation = this.translateService.instant('MAILS.PRESENTATION');
    const translatedMail = this.translateService.instant('MAILS.MAIL');
    const translatedPhone = this.translateService.instant('MAILS.PHONE');
    const translatedFinish = this.translateService.instant('MAILS.WORK_FINISH');

    const mailText = 'mailto:hello@innporting.com?subject=' + translatedSubject + '&body=' + translatedPresentation + name + translatedMail + mail + translatedPhone + phone + translatedFinish;
    window.location.href = mailText;
  }

}
