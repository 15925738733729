import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CookiesService} from './services/cookies.service';
import {Router} from '@angular/router';

declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss', './cookie-consent-mediaquery.component.scss']
})
export class CookieConsentComponent implements OnInit {
  private cookieKeyName: String;

  @ViewChild('cookieConsentBanner', {read: ElementRef}) banner: ElementRef;

  constructor(private cookieService: CookiesService, private router: Router) {
    this.cookieKeyName = 'cookies-accepted';
  }

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    if (!this.cookieService.checkCookieExistence(this.cookieKeyName) && !this.cookieService.checkCookieExistence('cookies-rejected')) {
      this.setInitialConsentMode();
    }

  }

  setInitialConsentMode() {
    try {
      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied',
        'wait_for_update': 500
      });
    } catch (e) {
      // Do nothing
    }
  }

  allConsentGranted() {
    try {
      gtag('consent', 'update', {
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
    } catch (e) {
      // Do nothing
    }
  }

  acceptCookies() {
    this.banner.nativeElement.classList.add('fadeOut');
    setTimeout(() => {
      this.cookieService.setCookie(this.cookieKeyName, true);
      this.allConsentGranted();
    }, 800);
  }

  rejectCookies() {
    this.banner.nativeElement.classList.add('fadeOut');
    setTimeout(() => {
      this.cookieService.setCookie('cookies-rejected', true);
    }, 800);
  }

  bannerAbleToShow() {
    return !this.cookieService.checkCookieExistence(this.cookieKeyName) && !this.cookieService.checkCookieExistence('cookies-rejected');
  }

  hasToScroll() {
    return this.router.url.includes('/legal-notice-conditions/cookies-policy');
  }

  goToCookiesSectionIfNeeded() {
    if (this.hasToScroll()) {
      const urlSegments = this.router.url.split('/');
      const sectionId = urlSegments[urlSegments.length - 1];
      if (sectionId) {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          const sectionYPosition = sectionElement.offsetTop;
          scrollTo(0, sectionYPosition + (window.innerHeight / 2));
        }
      }
    }
  }
}
