import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sea-shipments',
  templateUrl: './sea-shipments.component.html',
  styleUrls: ['./sea-shipments.component.scss']
})
export class SeaShipmentsComponent implements OnInit {

  title = 'Sea shipments';

  constructor() { }

  ngOnInit() {
  }

}
