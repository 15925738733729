import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss', './landing.mediaquery.scss']
})
export class LandingComponent implements OnInit {

  title = 'Landing';
  public focus;
  public dashBoardUrl;

  constructor() {
    this.dashBoardUrl = environment.dashboardURL + '/register';
  }

  ngOnInit() {
    try {
      const trustbox = document.getElementById('trustbox');
      window['Trustpilot'].loadFromElement(trustbox);
    } catch (e) {
      // Do nothing
    }
  }

  trackShipment() {
    const shipment = (<HTMLInputElement>document.getElementById('shipLandValue')).value;
    const url = 'https://dashboard.innporting.com/track-my-shipment/' + shipment;
    window.open(url, '_blank');
  }
}
