export class LocalStorageHelper {

  static readonly LANG_KEY: string = 'lang';
  static readonly TAB_SELECTED: string = 'tabSelected';

  static existsItem(key: string) {
    return localStorage.getItem(key) !== null;
  }

  static getItem(key: string) {
    return localStorage.getItem(key);
  }

  static setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }
}
