import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {PRIMARY_OUTLET, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {NavbarHelper} from './helpers/NavbarHelper';
import {TranslateService} from '@ngx-translate/core';
import {LanguageHelper} from '../../helpers/LanguageHelper';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss', 'navbar.mediaquery.scss']
})
export class NavbarComponent implements OnInit {
  @ViewChild('closeSidebarLayer') closeSidebarLayer: ElementRef;

  private toggleButton: any;
  private navBarHTML: HTMLElement;
  private sidebarVisible: boolean;
  public isNavTransparent: boolean;
  private navbarHelper: NavbarHelper;
  public languageHelper: LanguageHelper;
  private lockScrollElement;
  public dashBoardUrl;

  constructor(private element: ElementRef, private router: Router,
              @Inject(DOCUMENT) public document: any, private translate: TranslateService) {
    this.sidebarVisible = false;
    this.navbarHelper = new NavbarHelper();
    this.isNavTransparent = false;
    this.lockScrollElement = this.buildLockScrollElement();
    this.languageHelper = new LanguageHelper(this.translate);
    this.dashBoardUrl = environment.dashboardURL;
    this.initLanguages();
  }

  ngOnInit() {
    this.bindElements();
    this.setRouteEvents();
  }

  initLanguages() {
    this.languageHelper.initLanguage();
  }

  private bindElements() {
    this.toggleButton = this.element.nativeElement.getElementsByClassName('navbar-toggler')[0];
    this.navBarHTML = this.element.nativeElement.children[0];
  }

  private setRouteEvents() {
    this.router.events.subscribe(() => {
      this.closeSidebar();
      this.navbarTransparentGestion();
      this.scrollToTop();
    });
  }

  private scrollToTop() {
    window.scrollTo({left: 0, top: 0});
  }

  private scrollToTopSmoothly() {
    window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  }

  private navbarTransparentGestion() {
    const urlTree = this.router.parseUrl(this.router.url);
    const cleanUrl = `/${urlTree.root.children[PRIMARY_OUTLET] ? urlTree.root.children[PRIMARY_OUTLET].segments[0] : ''}`;
    this.isNavTransparent = this.navbarHelper.getTransparentFlagFromPagesMap(cleanUrl);
    this.toggleTransparency(this.isNavTransparent);
  }

  private toggleTransparency(makeTransparent: boolean) {
    if (makeTransparent) {
      this.navBarHTML.classList.add('navbar-transparent');
    } else {
      this.navBarHTML.classList.remove('navbar-transparent');
    }
  }

  private openSidebar() {
    this.closeSidebarLayer.nativeElement.classList.add('is-active');
    this.addAditionalStyleElement();
    const toggleButtonOnContext = this.toggleButton;
    const toggleButtonContainer = document.getElementsByClassName('navbar-translate')[0];
    const html = document.getElementsByTagName('html')[0];
    toggleButtonOnContext.classList.add('toggled');
    toggleButtonContainer.classList.add('toggled');
    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  private addAditionalStyleElement() {
    this.document.body.appendChild(this.lockScrollElement);
  }

  private removeAditionalStyleElement() {
    this.lockScrollElement.remove();
  }

  closeSidebar() {
    this.closeSidebarLayer.nativeElement.classList.remove('is-active');
    this.removeAditionalStyleElement();
    const html = document.getElementsByTagName('html')[0];
    const toggleButtonContainer = document.getElementsByClassName('navbar-translate')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
    toggleButtonContainer.classList.remove('toggled');
  }

  public toggleSidebar() {
    if (!this.sidebarVisible) {
      this.openSidebar();
    } else {
      this.closeSidebar();
    }
  }

  selectMenuElement(menuParent) {
    let select = false;
    switch (menuParent) {
      case NavbarHelper.SOLUTIONS_NAME:
        select = NavbarHelper.ARRAY_SOLUTIONS.indexOf(this.router.url) > -1;
        break;
      case NavbarHelper.ABOUT_NAME:
        select = NavbarHelper.ARRAY_ABOUT.indexOf(this.router.url) > -1;
        break;
      case NavbarHelper.SUPPORT_NAME:
        const url = this.router.url;
        const urlComponents = url.replace('/', '').split('/');
        let urlToFind = url;
        if  (urlComponents.length > 1) {
          urlToFind = '/' + urlComponents[0];
        }
        select = NavbarHelper.ARRAY_SUPPORT.indexOf(urlToFind) > -1;
        break;
    }
    return select;
  }

  private buildLockScrollElement() {
    const style: HTMLElement = document.createElement('style');
    style.textContent = `
            body {
              overflow: hidden !important ;
            }
        `;

    return style;
  }

  changeLanguage(lang: string) {
    this.languageHelper.changeLanguage(lang);
    this.closeSidebar();
  }

  public scrollToTopLanding() {
    if (this.router.url === '/landing') {
      this.scrollToTopSmoothly();
    }
  }

  // Be CAREFUL, this is for prevent navbar issues!
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const navBarIssueWidth = NavbarHelper.NAVBAR_CLOSED_WIDTH_ISSUE;
    const navBar: Element = this.element.nativeElement.getElementsByClassName('collapse navbar-collapse')[0];
    navBar.setAttribute('style',
      event.target.innerWidth < navBarIssueWidth ? 'display:block;' : 'display:none;');
    if (event.target.innerWidth > NavbarHelper.NAVBAR_CLOSE_INDICATOR_WIDTH
      && this.sidebarVisible) {
      this.closeSidebar();
    }
  }

  /**
   * This is for the transparent navBar, remember to put a 62px padding-top for the content!
   */
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.isNavTransparent) {
      const limitY = NavbarHelper.NAVBAR_MAXIMUM_Y_FOR_TOGGLE_TRANSPARENT;
      const scrollY = window.scrollY;
      this.toggleTransparency(scrollY < limitY || window.pageYOffset < limitY);
    }
  }

  closeSidebarIfOpen() {
    const target = event.target || event.srcElement;
    const toggleButtonContainer = document.getElementsByClassName('navbar-translate')[0];
    if (target === toggleButtonContainer && toggleButtonContainer.classList.contains('toggled')) {
      this.closeSidebar();
    }
  }
}
