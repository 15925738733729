import {OnInit} from '@angular/core';
import {TabHelper} from '../../helpers/TabHelper';
import {NgbTabChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {BasePageComponent} from './base/BasePageComponent';

export class TabPageComponent extends BasePageComponent implements OnInit {

  public tabHelper: TabHelper;
  public activeId: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.tabHelper = new TabHelper();
    this.activeId = this.tabHelper.getCurrentTab();
  }

  public onTabChange(event: NgbTabChangeEvent) {
    this.tabHelper.setTab(event.nextId);
  }
}
