import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-track-trace',
  templateUrl: './track-trace.component.html',
  styleUrls: ['./track-trace.component.scss']
})
export class TrackTraceComponent implements OnInit {

  public focus;

  constructor() {
  }

  ngOnInit() {
  }

  trackShipment() {
    const shipment = (<HTMLInputElement>document.getElementById('shipValue')).value;
    const url = 'https://dashboard.innporting.com/track-my-shipment/' + shipment;
    window.open(url, '_blank');
  }

}
